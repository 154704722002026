import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';

import { SettingsApiService } from '@fleet/api';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OnscreenNotificationService } from '@fleet/ui';
import { NetworkGroupService } from '@fleet/network-group';

import {
  ApiResponse,
  IssueModel,
  SettingDefinitionModel,
  SettingModel,
  SettingSearchResultModel,
} from '@fleet/model';
import { SettingService } from '../setting.service';
import { Observable, filter, of, switchMap, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  template: '',
})
export class SettingBaseComponent {
  setting: SettingModel;
  issues: IssueModel[] = [];
  saving = false;
  loading = false;
  settingDefinition: SettingDefinitionModel;
  originalSetting: any;
  networkId: string;
  networkGroupId: string;

  fields: FormlyFieldConfig[] = [];
  model: any = {};
  @Output() settingCreated = new EventEmitter<SettingModel>();
  @Output() settingUpdated = new EventEmitter<SettingModel>();

  constructor(
    protected settingsApiService: SettingsApiService,
    protected settingService: SettingService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected onscreenNotificationService: OnscreenNotificationService,
    protected networkGroupService: NetworkGroupService
  ) {
    this.networkGroupService.networkGroup$
      .pipe(takeUntilDestroyed())
      .subscribe((networkGroup) => {
        this.networkId = networkGroup?.networkId;
        this.networkGroupId = networkGroup?.networkGroupId;
      });

    this.settingService.issues$
      .pipe(takeUntilDestroyed())
      .subscribe((issues: IssueModel[]) => {
        this.issues = issues;
        this.changeDetectorRef.markForCheck();
      });
  }

  getSettingByPath(path: string): Observable<any> {
    return this.settingService.settingByPath$(path).pipe(
      takeUntilDestroyed(),
      switchMap((setting: SettingSearchResultModel) => {
        if (setting) {
          this.loading = true;
          this.changeDetectorRef.markForCheck();
          return this.settingsApiService.getSettingDefinition(
            setting.settingDefinitionId
          );
        }
        return of(null);
      }),
      filter((resp) => resp !== null),
      tap((resp: ApiResponse<SettingDefinitionModel>) => {
        this.loading = false;
        this.settingDefinition = resp.data;
        this.originalSetting = JSON.parse(
          JSON.stringify(this.settingDefinition.setting.data)
        );
        this.setting = this.settingDefinition.setting;
        this.model = JSON.parse(
          JSON.stringify(this.settingDefinition.setting.data)
        );
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  saveSetting(data: any) {
    this.issues = [];
    this.saving = true;
    if (this.setting.defaultSetting === false) {
      this.updateSetting({ ...this.setting, data: data });
    } else {
      this.createSetting({
        data: data,
        settingDefinitionId: this.settingDefinition.settingDefinitionId,
        networkId: this.networkId,
        networkGroupId: this.networkGroupId,
      } as SettingModel);
    }
  }

  createSetting(setting: SettingModel) {
    this.settingsApiService.createSetting(setting).subscribe({
      next: (resp: ApiResponse<SettingModel>) => {
        this.setting = resp.data;
        this.settingCreated.emit(this.setting);
        this.settingService.upsertSetting(this.setting);
        this.saving = false;
        this.onscreenNotificationService.setNotification({
          title: 'Success',
          subTitle: 'Setting updated successfully',
          autoDismiss: 3000,
        });
      },
      error: (issues: IssueModel[]) => {
        this.issues = issues;
        this.saving = false;
        this.changeDetectorRef.markForCheck();
        this.onscreenNotificationService.setNotification({
          title: 'Error',
          subTitle: 'Failed to update setting',
          autoDismiss: 3000,
        });
      },
    });
  }

  updateSetting(setting: SettingModel) {
    this.settingsApiService
      .updateConfiguration(setting.settingId, setting)
      .subscribe({
        next: (resp: ApiResponse<SettingModel>) => {
          this.setting = resp.data;
          this.settingUpdated.emit(resp.data);
          this.settingService.upsertSetting(resp.data);
          this.saving = false;
          this.onscreenNotificationService.setNotification({
            title: 'Success',
            subTitle: 'Setting updated successfully',
            autoDismiss: 3000,
          });
        },

        error: (issues: IssueModel[]) => {
          this.issues = issues;
          this.saving = false;
          this.changeDetectorRef.markForCheck();
          this.onscreenNotificationService.setNotification({
            title: 'Error',
            subTitle: 'Failed to update setting',
            autoDismiss: 3000,
          });
        },
      });
  }
}
