import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import {
  AcknowledgementModel,
  ApiResponse,
  FareModel,
  IssueLevel,
  IssueModel,
  JobBidModel,
  JobImportFileModel,
  JobImportRowModel,
  JobLifecycleListenerModel,
  JobModel,
  JobTemplateModel,
  JobTimelineModel,
  JobTransitionModel,
  PaymentMethodModel,
  ReceiptModel,
  SaleModel,
} from '@fleet/model';
import { encodeParams, handleApiError } from '@fleet/utilities';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { paths } from '@fleet/environment';

@Injectable({ providedIn: 'root' })
export class JobApiService {
  host: string;
  nonAuthorisedHttp: HttpClient = new HttpClient(this.backend);

  constructor(
    private http: HttpClient,
    @Inject('env') env: any,
    private backend: HttpBackend
  ) {
    this.host = env.host + paths.job;
  }

  searchJobs(params: { limit?: string; offset?: string; jobType?: string }) {
    // Available values : RANK_HAIL, BOOKED, ON_DEMAND, SCHEDULED
    // Default value : BOOKED

    return this.http
      .get(`${this.host}`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  // POST
  // ​/api​/v3​/job
  // Creates a job
  createJob(job: JobModel): Observable<JobModel | any> {
    return this.http
      .post(`${this.host}`, job)
      .pipe(catchError(handleJobCreateApiError));
  }

  updateJob(job: JobModel): Observable<JobModel | any> {
    return this.http
      .put(`${this.host}/${job.jobId}`, job)
      .pipe(catchError(handleJobCreateApiError));
  }

  dispatchJob(job: JobModel) {
    return this.http
      .post(`${this.host}/${job.jobId}/dispatch`, job)
      .pipe(catchError(handleJobCreateApiError));
  }

  dispatchJobWithFullResponse(job: JobModel): Observable<any> {
    return this.http
      .post(`${this.host}/${job.jobId}/dispatch`, job, { observe: 'response' })
      .pipe(catchError(handleJobCreateApiError));
  }

  // POST
  // ​/api​/v3​/job​/{jobId}​/start
  // Start the job
  startJob(jobId: string) {
    return this.http
      .post(`${this.host}/${jobId}/start`, {})
      .pipe(catchError(handleApiError));
  }

  // GET
  // ​/api​/v3​/job​/{jobId}​/fare
  // Retrieve the fare breakdown for a job
  jobFare(jobId: string) {
    return this.http
      .get(`${this.host}/${jobId}/fare`)
      .pipe(catchError(handleApiError));
  }

  // POST

  // Finalise the full fare prior to completing a job
  finaliseJob(jobId: string, payload: FareModel) {
    return this.http
      .post(`${this.host}/${jobId}/fare`, payload)
      .pipe(catchError(handleApiError));
  }

  // POST

  // Request the anonymised contact numbers for each party of the booking
  requestContacts(jobId: string) {
    return this.http
      .post(`${this.host}/${jobId}/contact`, {})
      .pipe(catchError(handleApiError));
  }

  // POST

  // Confirm that the driver is en-route to the pickup location
  confirmJob(jobId: string) {
    return this.http
      .post(`${this.host}/${jobId}/confirm`, {})
      .pipe(catchError(handleApiError));
  }

  // POST

  // Complete the job
  completeJob(jobId: string) {
    return this.http
      .post(`${this.host}/${jobId}/complete`, {})
      .pipe(catchError(handleApiError));
  }

  // POST
  // ​/api​/v3​/job​/{jobId}​/cancel
  // Cancels a job
  cancelJob(jobId: string, reason?: string): Observable<JobModel | any> {
    return this.http
      .post(`${this.host}/${jobId}/cancel`, reason ? { reason: reason } : {})
      .pipe(catchError(handleApiError));
  }

  deleteJob(jobId: string) {
    return this.http
      .delete(`${this.host}/${jobId}`)
      .pipe(catchError(handleApiError));
  }

  // POST

  // Acknowledge creation of a job
  acknowledgeJob(
    jobId: string,
    acknowledgement: AcknowledgementModel
  ): Observable<any> {
    return this.http.post(`${this.host}/${jobId}/acknowledge`, acknowledgement);
  }

  // POST

  // Abandons a job
  abandonJob(jobId: string) {
    return this.http
      .post(`${this.host}/${jobId}/abandon`, {})
      .pipe(catchError(handleApiError));
  }

  // GET

  // Retrieve a job by ID
  getJob(jobId: string): Observable<JobModel | any> {
    return this.http
      .get(`${this.host}/${jobId}`)
      .pipe(catchError(handleApiError));
  }

  getJobFullResponse(jobId: string): Observable<JobModel | any> {
    return this.http
      .get(`${this.host}/${jobId}`, { observe: 'response' })
      .pipe(catchError(handleApiError));
  }

  assignJob(jobId: string, driverId: string): Observable<JobModel | any> {
    return this.http
      .post(`${this.host}/${jobId}/assign/${driverId}`, {})
      .pipe(catchError(handleApiError));
  }

  unassignJob(
    jobId: string,
    jobTransitionsModel: JobTransitionModel
  ): Observable<JobModel | any> {
    return this.http
      .post(`${this.host}/${jobId}/unassign`, jobTransitionsModel)
      .pipe(catchError(handleApiError));
  }

  //   POST

  // Bid on a job
  bidOnJob(jobId: string, payload: JobBidModel) {
    return this.http
      .post(`${this.host}/${jobId}/bid`, payload)
      .pipe(catchError(handleApiError));
  }

  // Accept a bid
  acceptJobBid(jobId: string, jobBidId: string) {
    return this.http.post(`${this.host}/${jobId}/bid/${jobBidId}/accept`, {});
  }

  // GET

  // Get a specific job bid
  getJobBid(jobId: string, jobBidId: string) {
    return this.http
      .get(`${this.host}/${jobId}/bid/${jobBidId}`)
      .pipe(catchError(handleApiError));
  }

  // DELETE

  // Withdraw a job bid
  withdrawBid(jobId: string) {
    return this.http
      .delete(`${this.host}/${jobId}/bid`)
      .pipe(catchError(handleApiError));
  }

  // PATCH

  // Update the bid price on a job
  updatePriceOnJob(jobId: string, jobBid: string, payload: JobBidModel) {
    return this.http.patch(`${this.host}/${jobId}/bid/${jobBid}`, payload);
  }

  // GET

  // Retrieve bids for all active jobs for a passenger or driver
  retrieveJobBids(payload: { jobId: string; limit: string; offset: string }) {
    return this.http
      .get(`${this.host}/bid`, { params: payload })
      .pipe(catchError(handleApiError));
  }

  //   POST

  // Departing a location
  departLocation(jobId: string, locationId: string) {
    return this.http
      .get(`${this.host}/${jobId}/location/${locationId}/depart`)
      .pipe(catchError(handleApiError));
  }

  // POST

  // Arrived at a location
  arrivedAtLocation(jobId: string, locationId: string) {
    return this.http
      .post(`${this.host}/${jobId}/location/${locationId}/arrive`, {})
      .pipe(catchError(handleApiError));
  }

  // POST

  // Approaching a location
  // Job Offer
  approachLocation(jobId: string, locationId: string) {
    return this.http
      .post(`${this.host}/${jobId}/location/${locationId}/approach`, {})
      .pipe(catchError(handleApiError));
  }

  // POST

  // Decline a job offer
  declineJob(jobId: string) {
    return this.http
      .post(`${this.host}/offer/${jobId}/decline`, {})
      .pipe(catchError(handleApiError));
  }

  // GET

  // Search job offers
  getJobOffers(payload: { limit: string; offset: string }) {
    return this.http
      .get(`${this.host}/offer`, { params: payload })
      .pipe(catchError(handleApiError));
  }

  getTimeline(jobId: string): Observable<ApiResponse<JobTimelineModel> | any> {
    return this.http
      .get(`${this.host}/${jobId}/timeline`)
      .pipe(catchError(handleApiError));
  }

  getLink(token: string): Observable<any> {
    return this.http
      .get(`${this.host}/link/${token}`, { observe: 'response' })
      .pipe(catchError(handleApiError));
  }

  ///JOB LISTENERS

  getListener(listenerId: string) {
    return this.http
      .get(`${this.host}/listener/${listenerId}`)
      .pipe(catchError(handleApiError));
  }

  deregisterListener(listenerId: string) {
    return this.http
      .delete(`${this.host}/listener/${listenerId}`)
      .pipe(catchError(handleApiError));
  }

  registerListener(listener: JobLifecycleListenerModel): Observable<any> {
    return this.http
      .post(`${this.host}/listener`, listener)
      .pipe(catchError(handleApiError));
  }

  updateListener(listener: JobLifecycleListenerModel): Observable<any> {
    return this.http
      .put(`${this.host}/listener/${listener.listenerId}`, listener)
      .pipe(catchError(handleApiError));
  }

  dismissJobFromListener(listenerId: string, jobId: string): Observable<any> {
    return this.http
      .post(`${this.host}/listener/${listenerId}/job/${jobId}/dismiss`, {})
      .pipe(catchError(handleApiError));
  }

  createJobImport(jobImport: JobImportFileModel): Observable<any> {
    return this.http
      .post(`${this.host}/importer`, jobImport)
      .pipe(catchError(handleApiError));
  }

  getJobImport(jobImportFileId: string): Observable<any> {
    return this.http
      .get(`${this.host}/importer/${jobImportFileId}`)
      .pipe(catchError(handleApiError));
  }

  importJobImport(jobImportFileId: string): Observable<any> {
    return this.http
      .post(`${this.host}/importer/${jobImportFileId}/import`, {})
      .pipe(catchError(handleApiError));
  }

  searchJobImports(params: any): Observable<any> {
    return this.http
      .get(`${this.host}/importer`, {
        params: encodeParams(params),
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  searchJobImportRows(jobImportFileId: string, params: any): Observable<any> {
    return this.http
      .get(`${this.host}/importer/${jobImportFileId}/row`, {
        params: encodeParams(params),
        observe: 'response',
      })
      .pipe(catchError(handleApiError));
  }

  getJobImportRow(
    jobImportFileId: string,
    jobImportRowId: string
  ): Observable<any> {
    return this.http
      .get(`${this.host}/importer/${jobImportFileId}/row/${jobImportRowId}`)
      .pipe(catchError(handleApiError));
  }

  updateJobImportRow(row: JobImportRowModel): Observable<any> {
    return this.http
      .put(
        `${this.host}/importer/${row.jobImportFileId}/row/${row.jobImportRowId}`,
        row
      )
      .pipe(catchError(handleApiError));
  }

  processJobsFromFile(
    jobImportFileId: string,
    documentId: string
  ): Observable<any> {
    return this.http
      .post(
        `${this.host}/importer/${jobImportFileId}/processUpload/${documentId}`,
        {}
      )
      .pipe(catchError(handleApiError));
  }
  verifyJobImportRow(
    jobImportFileId: string,
    jobImportRowId: string
  ): Observable<any> {
    return this.http
      .post(
        `${this.host}/importer/${jobImportFileId}/row/${jobImportRowId}/verify`,
        {}
      )
      .pipe(catchError(handleApiError));
  }

  deleteJobImportRow(
    jobImportFileId: string,
    jobImportRowId: string
  ): Observable<any> {
    return this.http
      .delete(`${this.host}/importer/${jobImportFileId}/row/${jobImportRowId}`)
      .pipe(catchError(handleApiError));
  }

  createJobTemplate(jobTemplate: JobTemplateModel): Observable<JobModel | any> {
    return this.http
      .post(`${this.host}/template`, jobTemplate)
      .pipe(catchError(handleApiError));
  }
  updateJobTemplate(jobTemplate: JobTemplateModel): Observable<JobModel | any> {
    return this.http
      .put(`${this.host}/template/` + jobTemplate.jobTemplateId, jobTemplate)
      .pipe(catchError(handleApiError));
  }

  deleteJobTemplate(jobTemplateId: string): Observable<any> {
    return this.http
      .delete(`${this.host}/template/` + jobTemplateId)
      .pipe(catchError(handleApiError));
  }

  searchJobTemplates(params: any) {
    return this.http
      .get(`${this.host}/template`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  generatePin(): Observable<any> {
    return this.http
      .get(`${this.host}/template/pin`)
      .pipe(retry(3), catchError(handleApiError));
  }

  call(jobId: string): Observable<ApiResponse<JobModel> | IssueModel[] | any> {
    return this.http
      .post(`${this.host}/${jobId}/call`, {})
      .pipe(catchError(handleApiError));
  }

  createPaymentMethod(
    jobId: string,
    paymentMethod: PaymentMethodModel | any
  ): Observable<JobModel | any> {
    return this.http
      .post(`${this.host}/${jobId}/paymentMethod`, paymentMethod)
      .pipe(catchError(handleApiError));
  }

  pay(jobId: string, sale: SaleModel): Observable<any> {
    return this.http
      .post(`${this.host}/${jobId}/pay`, sale)
      .pipe(catchError(handleApiError));
  }

  calculateSale(jobId: string, sale: SaleModel): Observable<any> {
    return this.http
      .post(`${this.host}/${jobId}/sale`, sale)
      .pipe(catchError(handleApiError));
  }

  searchDispatchLogs(jobId: string, params: any) {
    return this.http
      .get(`${this.host}/${jobId}/dispatchLog`, {
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  getJobTemplate(jobTemplateId: string) {
    return this.http
      .get(`${this.host}/template/${jobTemplateId}`)
      .pipe(catchError(handleApiError));
  }

  receipt(jobId: string, receipt: ReceiptModel) {
    return this.http
      .post(`${this.host}/${jobId}/receipt`, receipt)
      .pipe(catchError(handleApiError));
  }

  extendExpiry(jobId: string): Observable<any> {
    return this.http
      .post(`${this.host}/${jobId}/extendExpiry`, {})
      .pipe(catchError(handleApiError));
  }

  deleteJobImportFile(
    jobImportFileId: string
  ): Observable<ApiResponse<null> | IssueModel[] | any> {
    return this.http
      .delete(`${this.host}/importer/${jobImportFileId}`)
      .pipe(catchError(handleApiError));
  }
}

export function handleJobCreateApiError(
  httpErrorResponse: HttpErrorResponse
): Observable<IssueModel[]> {
  let issues: IssueModel[] = [];
  if (httpErrorResponse.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('An error occurred:', httpErrorResponse.error.message);
    issues.push(<IssueModel>{
      message:
        'There was an issue contacting the server, please try again later',
      level: IssueLevel.ERROR,
    });
  } else {
    switch (httpErrorResponse.status) {
      case 0:
        issues.push(<IssueModel>{
          message:
            'There was an issue contacting the server, please try again later',
          level: IssueLevel.ERROR,
        });
        // issue.type = "ERROR";
        break;
      case 401:
        issues.push(<IssueModel>{
          message: httpErrorResponse.url.includes('signin')
            ? 'Username or password incorrect'
            : 'Not Authenticated',
          level: IssueLevel.ERROR,
        });
        break;
      case 403:
        issues.push(<IssueModel>{
          message: 'Not Authorized',
          level: IssueLevel.ERROR,
        });
        break;
      case 400:
      case 402:
      case 404:
      case 500:
        issues =
          httpErrorResponse.error &&
          httpErrorResponse.error.status &&
          httpErrorResponse.error.status.issues
            ? httpErrorResponse.error.status.issues.filter(
                (issue: IssueModel) => issue.type || issue.message
              )
            : httpErrorResponse.error &&
              httpErrorResponse.error.status &&
              httpErrorResponse.error.status.messages
            ? httpErrorResponse.error.status.messages
            : [
                {
                  message:
                    'An error has occurred.  Please try again, or contact support@ingogo.mobi for further assistance.',
                  level: IssueLevel.ERROR,
                },
              ];

        break;
      case 503:
      case 504:
        issues.push(<IssueModel>{
          message: 'Server is not responding. Please have a try later.',
          level: IssueLevel.ERROR,
        });
        break;
      case 409:
        issues = httpErrorResponse.error
          ? httpErrorResponse.error.status.issues
          : [
              {
                message:
                  'An error has occurred.  Please try again, or contact support@ingogo.mobi for further assistance.',
                level: IssueLevel.ERROR,
              },
            ];

        break;

      default:
        issues.push(<IssueModel>{
          message:
            'There was an issue contacting the server, please try again later',
          level: IssueLevel.ERROR,
        });
        break;
    }
  }
  return throwError({
    issues: issues,
    status: httpErrorResponse.status,
    job: httpErrorResponse.error.data ? httpErrorResponse.error.data : null,
  });
}
