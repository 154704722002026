<div *ngIf="apiLoaded$ | async" class="map-wrapper">
  <ng-container *ngIf="mapReady$ | async; else loading">
    <google-map
      #googleMap
      *ngIf="map$ | async as map"
      [options]="map.options"
      [height]="height"
      width="100%"
      (mapClick)="click($event)"
      [center]="map.center"
      [zoom]="map.zoom"
      (mapMousemove)="mouseMove($event)"
    >
      <!-- ACTIVE JOBS VIEW -->
      <ng-container *ngIf="!selectedActiveJob">
        <!-- <map-marker
          #markerElem="mapMarker"
          *ngFor="
            let marker of activeJobMarkers;
            let i = index;
            let first = first;
            let last = last
          "
          [position]="marker.position"
          [icon]="marker.icon"
          (mapClick)="activeJobClicked(marker, markerElem)"
        ></map-marker> -->
        <map-advanced-marker
          #markerElem="mapAdvancedMarker"
          *ngFor="
            let marker of activeJobMarkers;
            let i = index;
            let first = first;
            let last = last
          "
          [content]="marker.content"
          [position]="marker.position"
          (mapClick)="activeJobClicked(marker, markerElem)"
        ></map-advanced-marker>
        <map-polygon
          *ngFor="let poly of jobAggregationsPolygons$ | async"
          [options]="poly"
        ></map-polygon>
      </ng-container>
      <map-info-window #activeJobInfoWindow (closeclick)="deselectActiveJob()">
        <fleet-active-job-card
          [isCard]="false"
          class="cursor-pointer"
          [paddingClasses]="'p-0'"
          *ngIf="selectedActiveJob"
          [job]="selectedActiveJob"
          (click)="activeJobSelected.emit(selectedActiveJob)"
          [showActionButton]="true"
        ></fleet-active-job-card>
      </map-info-window>

      <map-heatmap-layer
        *ngIf="heatMapData$ | async as heatMapData"
        [data]="heatMapData"
        [options]="map.heatmapOptions"
      >
      </map-heatmap-layer>
      <ng-container *ngIf="viewMode === 'view' && !map.centeredMapMarker">
        <map-advanced-marker
          *ngFor="
            let marker of markers$ | async;
            let i = index;
            let first = first;
            let last = last
          "
          [options]="marker"
          (mapDragend)="markerDragEnd(i, $event)"
        ></map-advanced-marker>
        <map-marker
          *ngIf="testMarker"
          [position]="testMarker.position"
        ></map-marker>
        <map-marker
          *ngIf="vehicleMarker"
          [position]="vehicleMarker.position"
          [icon]="vehicleMarker.icon"
        ></map-marker>
        <map-marker
          #markerElem="mapMarker"
          *ngFor="
            let marker of vMarkers;
            let i = index;
            let first = first;
            let last = last
          "
          [position]="marker.position"
          [icon]="marker.icon"
          (mapClick)="vehiclePositionClicked(marker, markerElem)"
        >
        </map-marker>
        <map-info-window #positionDetailWindow>
          <fleet-position-detail-card
            [positionDetail]="selectedPositionDetail"
          ></fleet-position-detail-card>
        </map-info-window>
        <map-polyline
          *ngFor="let polyline of routePolyLines$ | async"
          [options]="polyline"
        ></map-polyline>
        <map-polygon
          *ngIf="map.boundary"
          [options]="map.boundary"
        ></map-polygon>
        <map-polygon
          *ngFor="let polygon of areaPolygons$ | async"
          [options]="polygon"
          (polygonClick)="areaPolygonClicked(polygon)"
        ></map-polygon>
        <map-polygon
          *ngFor="let poly of map.resolutionPolygons"
          [options]="poly"
        ></map-polygon>
        <map-polygon
          *ngFor="let poly of vehicleRankingPolygons$ | async"
          [options]="poly"
        ></map-polygon>
        <map-circle *ngIf="map.radius" [options]="map.radius"></map-circle>
        <map-circle
          *ngIf="radiusCircle$ | async as radiusCircle"
          [options]="radiusCircle"
        ></map-circle>
        <map-polygon
          *ngFor="let poly of vehicleAggregationsPolygons$ | async"
          [options]="poly"
        ></map-polygon>
        <map-polyline
          #mapPolyline
          *ngIf="polyline"
          [options]="polyline"
          (polylineClick)="polylineClicked($event)"
          (polylineDragend)="polylineDragend($event)"
          (polylineMouseup)="polylineMouseUp($event)"
          (polylineMousedown)="polylineMousedown($event)"
          (polylineDblclick)="polylineDoubleClick($event)"
        >
        </map-polyline>
        <map-marker
          *ngIf="selectedPolylineMarker"
          [position]="selectedPolylineMarker.position"
          [icon]="selectedPolylineMarker.icon"
        ></map-marker>
        <map-info-window #polygonMarkerInfoWindow>
          <div class="flex flex-col gap-1" *ngIf="selectedPolylinePath">
            <div>
              {{ selectedPolylinePath[0] }},{{ selectedPolylinePath[1] }}
            </div>
            <div *ngIf="selectedPolylinePath[2]">
              {{
                selectedPolylinePath[2]
                  | internationalizationDate
                    : 'LOCALIZED_TIME'
                    : null
                    : 'millis'
              }}
            </div>
            <button (click)="deletePolyMarker(selectedPolylinePath)">
              delete
            </button>
          </div>
        </map-info-window>
        <button
          *ngIf="map.showConfirmButton"
          class="absolute mx-auto max-w-60 bottom-5 left-0 right-0 z-10 bg-primary-600 text-white disabled:bg-gray-200"
          mat-raised-button
          (click)="confirmMovedMarker()"
        >
          CONFIRM LOCATION
        </button>
      </ng-container>
      <map-marker
        *ngIf="map.centeredMapMarker"
        [options]="map.centeredMapMarker"
      ></map-marker>
      <button
        *ngIf="map.showConfirmButton"
        class="absolute mx-auto max-w-60 bottom-5 left-0 right-0 z-10 bg-primary-600 text-white disabled:bg-gray-200"
        mat-raised-button
        (click)="confirmLocation()"
      >
        CONFIRM LOCATION
      </button>
    </google-map>
  </ng-container>
</div>
<ng-template #loading>
  <div class="flex items-center justify-center h-full w-full">
    <mat-spinner diameter="24"></mat-spinner>
  </div>
</ng-template>
