import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { paths } from '@fleet/environment';
import {
  ApiResponse,
  TicketCategoryModel,
  TicketConfigurationModel,
  TicketModel,
} from '@fleet/model';
import { encodeParams, handleApiError } from '@fleet/utilities';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TicketApiService {
  host: string;

  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.ticket;
  }

  searchTickets(
    params: any
  ): Observable<HttpResponse<ApiResponse<TicketModel[]>> | any> {
    return this.http
      .get(`${this.host}`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  createTicket(
    payload: TicketModel
  ): Observable<ApiResponse<TicketModel>> | any {
    return this.http
      .post(`${this.host}`, payload)
      .pipe(catchError(handleApiError));
  }

  updateTicket(
    payload: TicketModel
  ): Observable<ApiResponse<TicketModel>> | any {
    return this.http
      .put(`${this.host}/${payload.ticketId}`, payload)
      .pipe(catchError(handleApiError));
  }

  searchCategories(
    params: any
  ): Observable<HttpResponse<ApiResponse<TicketCategoryModel[]>> | any> {
    return this.http
      .get(`${this.host}/category`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  searchTicketConfigurations(
    params: any
  ): Observable<HttpResponse<ApiResponse<TicketConfigurationModel[]>> | any> {
    return this.http
      .get(`${this.host}/configuration`, {
        observe: 'response',
        params: encodeParams(params),
      })
      .pipe(catchError(handleApiError));
  }

  createTicketConfiguration(
    payload: TicketModel
  ): Observable<ApiResponse<TicketConfigurationModel>> | any {
    return this.http
      .post(`${this.host}/configuration`, payload)
      .pipe(catchError(handleApiError));
  }

  updateTicketConfiguration(
    payload: TicketConfigurationModel
  ): Observable<ApiResponse<TicketConfigurationModel>> | any {
    return this.http
      .put(
        `${this.host}/configuration/${payload.ticketConfigurationId}`,
        payload
      )
      .pipe(catchError(handleApiError));
  }

  deleteTicketConfiguration(
    ticketId: string
  ): Observable<ApiResponse<TicketConfigurationModel>> | any {
    return this.http
      .delete(`${this.host}/configuration/${ticketId}`)
      .pipe(catchError(handleApiError));
  }
}
