<div
  class="flex flex-row items-center"
  [ngClass]="{ 'shadow rounded-lg bg-card': isCard }"
  [ngClass]="paddingClasses"
>
  <img
    *ngIf="jobOrganisation.logoUrl; else placeHolder"
    class="w-14 h-14 mr-4 rounded-full object-cover"
    [src]="jobOrganisation.logoUrl"
    alt="Organization Logo"
    onerror="this.style.display='none'"
  />

  <ng-template #placeHolder>
    <span
      *ngIf="jobOrganisation.organisationDisplayName"
      class="inline-flex items-center justify-center w-14 h-14 mr-4 rounded-full bg-gray-500"
    >
      <span class="text-xl font-medium leading-none text-white">
        {{
          jobOrganisation.organisationDisplayName
            ? jobOrganisation.organisationDisplayName.substring(0, 1)
            : ''
        }}
      </span>
    </span>
  </ng-template>

  <div class="flex flex-col min-w-0">
    <div
      class="font-medium leading-none"
      [ngClass]="{ 'underline cursor-pointer': canNavigate }"
      (click)="canNavigate ? navigateToOrganisation() : null"
      [matTooltip]="'Organization'"
    >
      {{ jobOrganisation.organisationDisplayName }}
    </div>
    <div
      *ngIf="jobOrganisation.organisationGroupDisplayName"
      class="text-md leading-none text-secondary mt-1"
      [matTooltip]="'Organization Group'"
      [ngClass]="{ 'underline cursor-pointer': canNavigate }"
      (click)="canNavigate ? navigateToOrganisationGroup() : null"
    >
      {{ jobOrganisation.organisationGroupDisplayName }}
    </div>
  </div>
</div>
