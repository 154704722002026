import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiResponse } from '@fleet/model';
import { paths } from '@fleet/environment';
import { encodeParams, handleApiError } from '@fleet/utilities';

@Injectable({
  providedIn: 'root',
})
export class StripeApiService {
  private host: string;

  constructor(private http: HttpClient, @Inject('env') env: any) {
    this.host = env.host + paths.stripe;
  }

  /**
   * Return the public key for the stripe account
   *
   * @return Observable<string>
   */
  getPublicKey(): Observable<any> {
    return this.http
      .get<string>(`${this.host}/publicKey`)
      .pipe(catchError(handleApiError));
  }

  /**
   * Complete the setup intent that requires action
   *
   * @param paymentMethodModel
   * @return Observable<any>
   */
  setupIntentRequiresActionComplete(paymentMethodModel: any): Observable<any> {
    return this.http
      .put<any>(
        `${this.host}/setupIntent/requiresAction/complete`,
        paymentMethodModel
      )
      .pipe(catchError(handleApiError));
  }

  complete3DSAuthentication(paymentMethodModel: any): Observable<any> {
    return this.http
      .put<any>(`${this.host}/complete3DSAuthentication`, paymentMethodModel)
      .pipe(catchError(handleApiError));
  }
}
