import {
  CabchargeImportFileModel,
  FileImportStatus,
  JobImportFileModel,
  JobModel,
  LifecycleTransitionModel,
  NetworkConditionModel,
  OrganisationModel,
  TravellerModel,
  VehicleModel,
} from '@fleet/model';

import { DateTime } from 'luxon';
import {
  locationsArrayFromJobDetail,
  waypointLetterFromAlphabet,
} from './location-utilities';
import { titleCaseClean } from './string-utilities';
import { jobColorFromStatus } from './job-utilities';

export function entityHeaderFromTraveller(
  traveller: TravellerModel,
  fleetProduct: string
) {
  let name = '';
  if (!traveller.firstName && !traveller.lastName) {
    name = 'Name Unknown';
  } else {
    name = traveller.firstName + ' ' + traveller.lastName;
    name = name.replace('undefined', '').trim();
  }

  const header = {
    title: name,

    dataItems: [
      {
        isChip: true,
        label: traveller.status,

        link: '/traveller/' + traveller.travellerId + '/settings/status',
      },
    ],
    avatar: {
      image: traveller.photo ? traveller.photo.url : null,
    },
  };

  if (traveller.phoneNumber) {
    if (fleetProduct === 'HUB') {
      header.dataItems.push({
        type: 'DIALABLE_PHONENUMBER',

        dialablePhoneNumber: {
          phoneNumber: traveller.phoneNumber,
          labelIcon: 'phone',
          tooltip: 'Call Traveller',
        },
      } as any);
    } else {
      header.dataItems.push({
        label: traveller.phoneNumber,
        icon: 'phone',
      } as any);
    }
  }
  if (traveller.username) {
    header.dataItems.push({ label: traveller.username, icon: 'mail' } as any);
  }
  return header;
}

export function entityHeaderFromOrganisation(org: OrganisationModel) {
  {
    return {
      title: org.name,
      dataItems: [
        {
          label: org.organisationCode,
          icon: 'tag',
          toolTip: 'Organization code',
        },
        {
          label:
            org.abn.slice(0, 2) +
            ' ' +
            org.abn.slice(2, 5) +
            ' ' +
            org.abn.slice(5, 8) +
            ' ' +
            org.abn.slice(8, 11),
          icon: 'business',
          toolTip: 'ABN',
        },
        {
          label: org.emailDomain,
          icon: 'mail',
          toolTip: 'Email',
        },
      ],
      avatar: org.logoUrl
        ? {
            image: org.logoUrl,
            initials: org.name.substring(0, 1),
          }
        : null,
    };
  }
}

export function entityHeaderFromVehicle(vehicle: VehicleModel) {
  let name = '';
  if (!vehicle.plate || !vehicle.plate.plateNumber) {
    name = 'Plate Unknown';
  } else {
    name = vehicle.plate.plateNumber;
  }

  const dataItems = [
    {
      isChip: true,
      label: vehicle.status,
    },
    {
      label: vehicle.make + '/' + vehicle.model,
      icon: 'directions_car',
      toolTip: 'Make/Model',
    },
  ];

  if (vehicle.bodyType) {
    dataItems.push({
      label: vehicle.bodyType,
      icon: 'badge',
      toolTip: 'Body Type',
    }) as any;
  }
  if (vehicle.yearOfManufacture) {
    dataItems.push({
      label: vehicle.yearOfManufacture.toString(),
      icon: 'calendar_today',
      toolTip: 'Manufacture Year',
    }) as any;
  }

  return {
    title: name,
    dataItems: dataItems,
    avatar: {
      image: vehicle.vehiclePhotoFront ? vehicle.vehiclePhotoFront.url : null,
    },
  };
}

export function orderLifecycleDescending(
  a: LifecycleTransitionModel,
  b: LifecycleTransitionModel
) {
  let first;
  let second;
  if (a.created && b.created) {
    first = DateTime.fromISO(a.created.toString());
    second = DateTime.fromISO(b.created.toString());
  } else {
    first = DateTime.fromISO(a.effectiveFrom.toString());
    second = DateTime.fromISO(b.effectiveFrom.toString());
  }

  return second.toMillis() - first.toMillis();
}

export function entityHeaderFromJob(
  job: JobModel,
  fleetProduct: string,
  showConditions?: boolean,
  pageLabel?: string,
  orgUrl?: string,
  linkJobId?: boolean
) {
  const dataItems: any[] = [
    {
      label: titleCaseClean(job.jobStatus),
      type: 'BADGE',
      badgeColor: jobColorFromStatus(job.jobStatus)?.badgeColor,
    },
    {
      label: job.jobId,
      toolTip: 'Job Reference',
      icon: 'tag',
      link: linkJobId ? '/job/' + job.jobId : null,
    },
  ];
  const waypointPlacesWithLetter = [];
  // const waypoints = placeModelArrayFromJobDetail(job.jobDetail);
  const waypointLocations = locationsArrayFromJobDetail(job.jobDetail);
  for (let i = 0; i < waypointLocations.length; i++) {
    if (waypointLocations[i]) {
      waypointPlacesWithLetter.push({
        location: waypointLocations[i],
        letter: waypointLetterFromAlphabet(i),
      });
    }
  }
  waypointPlacesWithLetter.forEach((w) => {
    if (
      job.jobProgress &&
      job.jobProgress.activeLocation &&
      job.jobProgress.activeLocation.locationId === w.location.locationId
    ) {
      if (job.jobProgress.activeLocation.status === 'ARRIVED') {
        dataItems.push({
          label: w.location.displayLine2 + ' (ARRIVED)',
          icon: w.letter,
        } as any);
      } else {
        dataItems.push({
          label:
            w.location.displayLine2 +
            ' (' +
            job.jobProgress.activeLocation.status +
            ' ' +
            job.jobProgress.displayEta +
            ')',
          icon: w.letter,
        } as any);
      }
    } else {
      dataItems.push({ label: w.location.displayLine2, icon: w.letter } as any);
    }
  });

  if (job.jobDetail.networkServiceDisplayName) {
    dataItems.push({
      label: job.jobDetail.networkServiceDisplayName,
      icon: 'local_taxi',
    });
  }

  if (job.driver) {
    if (job.driver.contactNumber && fleetProduct === 'HUB') {
      dataItems.push({
        label: job.driver.name,
        icon: 'badge',
        type: 'STANDARD',
        dialablePhoneNumber: {
          phoneNumber: job.driver.contactNumber,

          tooltip: 'Call Driver',
        },
      });
    } else {
      dataItems.push({ label: job.driver.name, icon: 'badge' });
    }
  }
  if (job.vehicle) {
    dataItems.push({
      label: job.vehicle.plate.plateNumber,
      icon: 'local_taxi',
    });
  }
  if (job.traveller) {
    if (job.traveller.contactNumber) {
      if (fleetProduct === 'HUB') {
        dataItems.push({
          label: job.traveller.name,
          icon: 'hail',
          type: 'STANDARD',
          dialablePhoneNumber: {
            phoneNumber: job.traveller.contactNumber,
            tooltip: 'Call Traveller',
          },
        });
      } else {
        dataItems.push({ label: job.traveller.name, icon: 'hail' });
        dataItems.push({ label: job.traveller.contactNumber, icon: 'phone' });
      }
    } else {
      dataItems.push({ label: job.traveller.name, icon: 'hail' });
    }
  }
  if (job.jobDetail.conditions && job.jobDetail.conditions.length > 0) {
    job.jobDetail.conditions.forEach((condition: NetworkConditionModel) => {
      dataItems.push({
        icon: condition.iconName,
        label: condition.name,
      } as any);
    });
  }

  let title = waypointPlacesWithLetter[0]
    ? waypointPlacesWithLetter[0].location.displayLine1
    : 'No locations';
  title =
    title +
    ' - ' +
    DateTime.fromISO(job.jobDetail.startTime.toString(), {
      setZone: true,
    })
      .toLocaleString({
        ...DateTime.DATETIME_FULL,

        timeZoneName: 'long',
      } as any)
      .replace('GMT', '');
  if (pageLabel) {
    //add breadcrumbs:
    const breadcrumbs: any[] = [
      {
        label: 'Jobs',
        clickable: true,
        prefixArrow: false,
        link: orgUrl + '/job/search',
      },
    ];
    waypointPlacesWithLetter[0]
      ? waypointPlacesWithLetter[0].location.displayLine1
      : 'No locations';

    breadcrumbs.push({
      label: waypointPlacesWithLetter[0]
        ? waypointPlacesWithLetter[0].location.displayLine1
        : 'Detail',

      prefixArrow: true,
      clickable: false,
    });

    breadcrumbs.push({
      label: pageLabel,
      prefixArrow: true,
      clickable: false,
    });
  }

  return {
    title: title,
    dataItems: dataItems,
  };
}

export function entityHeaderFromCabchargeImport(
  cabchargeImportFile: CabchargeImportFileModel,
  summary: any
) {
  const statusPill = entityStatusPill(cabchargeImportFile.fileImportStatus);
  const header = {
    title:
      'Cabcharge Import File' +
      (cabchargeImportFile?.document?.description
        ? ' - ' + cabchargeImportFile?.document?.description
        : ''),
    dataItems: [
      {
        isChip: true,
        textColor: statusPill.text,
        chipBgColor: statusPill.background,
        label: titleCaseClean(cabchargeImportFile.fileImportStatus),
        chipBgColorAndTextColor: statusPill.backgroundAndText,
      },
      {
        label: DateTime.fromISO(
          cabchargeImportFile.created.toString()
        ).toLocaleString(DateTime.DATETIME_FULL),

        icon: 'calendar_today',
        tooltip: 'Created',
      },
    ],
  };
  if (cabchargeImportFile.fileImportStatus === FileImportStatus.PROCESSED) {
    header.dataItems.push({
      label: summary.totalVerified + ' verified rows out of ' + summary.total,
      icon: 'verified',
    } as any);
    // header.dataItems.push({
    //   label: summary.totalValidationErrors + ' rows with validation errors '
    //   icon: 'verified',
    // } as any);
  }
  if (cabchargeImportFile.fileImportStatus === FileImportStatus.FAILED) {
    header.dataItems.push({
      label: summary.totalFailed + ' failed to import',
      icon: 'error',
    } as any);
    header.dataItems.push({
      label: summary.totalImported + ' adjustments imported',
      icon: 'check_circle',
    } as any);
  }
  if (cabchargeImportFile.fileImportStatus === FileImportStatus.IMPORTED) {
    header.dataItems.push({
      label: summary.totalImported + ' successfully imported',
      icon: 'check_circle',
    } as any);
  }

  return header;
}

export function entityHeaderFromJobImport(
  jobImportFile: JobImportFileModel,
  summary: any
) {
  const header = {
    title:
      'Job Import File' +
      (jobImportFile?.document?.description
        ? ' - ' + jobImportFile?.document?.description
        : ''),
    dataItems: [
      {
        isChip: true,
        label: jobImportFile.fileImportStatus,
      },
      {
        label: DateTime.fromISO(
          jobImportFile.created.toString()
        ).toLocaleString(DateTime.DATETIME_FULL),

        icon: 'calendar_today',
        tooltip: 'Created',
      },
    ],
  };
  if (jobImportFile.fileImportStatus === FileImportStatus.PROCESSED) {
    header.dataItems.push({
      label: summary.totalVerified + ' verified rows out of ' + summary.total,
      icon: 'verified',
    } as any);
    // header.dataItems.push({
    //   label: summary.totalValidationErrors + ' rows with validation errors '
    //   icon: 'verified',
    // } as any);
  }
  if (jobImportFile.fileImportStatus === FileImportStatus.FAILED) {
    header.dataItems.push({
      label: summary.totalFailed + ' failed to import',
      icon: 'error',
    } as any);
    header.dataItems.push({
      label: summary.totalImported + ' jobs imported',
      icon: 'check_circle',
    } as any);
  }
  if (jobImportFile.fileImportStatus === FileImportStatus.IMPORTED) {
    header.dataItems.push({
      label: summary.totalImported + ' successfully imported',
      icon: 'check_circle',
    } as any);
  }

  return header;
}

export function entityStatusPill(status: string, inverse?: boolean): any {
  if (status) {
    switch (status) {
      case 'ACTIVE':
        return inverse
          ? {
              icon: 'done',
              background: 'bg-green-600',
              text: 'text-green-100',
              backgroundAndText: 'bg-green-600 text-green-100',
            }
          : {
              icon: 'done',
              background: 'bg-green-600',
              text: ' text-green-100',
              backgroundAndText: 'bg-green-600  text-green-100',
            };
      case 'VALID':
      case 'IMPORTED':
      case 'FINISHED':
      case 'APPROVED':
      case 'PAID':
      case 'PUBLISHED':
      case 'IN_PROGRESS':
      case 'STARTED':
      case 'SETTLED':
      case 'AVAILABLE':
      case 'COMPLETED':
      case 'DIRECT_DEBITED':
        return inverse
          ? {
              icon: 'done',
              background: 'bg-green-600',
              text: 'text-green-100',
              backgroundAndText: 'bg-green-700 text-green-100',
            }
          : {
              icon: 'done',
              background: 'bg-green-100',
              text: 'text-green-600',
              backgroundAndText: 'bg-green-100 text-green-700',
            };
      case 'BANNED':
      case 'INVALID':
      case 'FAILED':
      case 'BLOCKED':
      case 'SETTLEMENT_REJECTED':
      case 'CANCELLED':
      case 'REJECTED':
      case 'DECLINED':
      case 'DIRECT_DEBIT_REJECTED':
        return inverse
          ? {
              background: 'bg-red-600',
              text: 'text-red-100',
              icon: 'block',
              backgroundAndText: 'bg-red-600 text-red-100',
            }
          : {
              background: 'bg-red-100',
              text: 'text-red-700',
              icon: 'block',
              backgroundAndText: 'bg-red-100 text-red-700',
            };
      case 'SUSPENDED':
      case 'VALIDATION_ERROR':
      case 'VERIFICATION_REQUIRED':
      case 'EXPIRED':
      case 'UNPUBLISHED':
      case 'ACKNOWLEDGEMENT_REQUIRED':
      case 'DISABLED':
      case 'LOST':
        return inverse
          ? {
              icon: 'block',
              background: 'bg-amber-500',
              text: 'text-amber-100',
              backgroundAndText: 'bg-amber-500 text-amber-100',
            }
          : {
              icon: 'block',
              background: 'bg-amber-100',
              text: 'text-amber-500',
              backgroundAndText: 'bg-amber-100 text-amber-500',
            };
      case 'PENDING_UNINSTALLATION':
      case 'OUTSTANDING':
        return inverse
          ? {
              icon: 'block',
              background: 'bg-pink-400',
              text: 'text-pink-100',
              backgroundAndText: 'bg-pink-400 text-pink-100',
            }
          : {
              icon: 'block',
              background: 'bg-pink-100',
              text: 'text-pink-400',
              backgroundAndText: 'bg-pink-100 text-pink-400',
            };
      case 'ON_LEAVE':
      case 'STICKY':
      case 'EXEMPTED':
        return inverse
          ? {
              icon: 'cottage',
              background: 'bg-yellow-400',
              text: 'text-yellow-100',
              backgroundAndText: 'bg-yellow-400 text-yellow-100',
            }
          : {
              icon: 'cottage',
              background: 'bg-yellow-100',
              text: 'text-yellow-400',
              backgroundAndText: 'bg-yellow-100 text-yellow-400',
            };
      case 'IN_ACTIVE':
      case 'OPEN':
      case 'INCOMPLETE':
      case 'ASSIGNED':
      case 'INFO_REQUIRED':
      case 'INACTIVE':
        return inverse
          ? {
              icon: 'info',
              background: 'bg-gray-700',
              text: 'text-gray-300',
              backgroundAndText: 'bg-gray-700 text-gray-300',
            }
          : {
              icon: 'info',
              background: 'bg-gray-300',
              text: 'text-gray-700',
              backgroundAndText: 'bg-gray-300 text-gray-700',
            };

      case 'VOID':
        return inverse
          ? {
              icon: 'block',
              background: 'bg-gray-700',
              text: 'text-gray-300',
              backgroundAndText: 'bg-gray-700 text-gray-300',
            }
          : {
              icon: 'block',
              background: 'bg-gray-300',
              text: 'text-gray-700',
              backgroundAndText: 'bg-gray-300 text-gray-700',
            };

      case 'DRAFT':
        return inverse
          ? {
              icon: 'pending',
              background: 'bg-gray-700',
              text: 'text-gray-300',
              backgroundAndText: 'bg-gray-700 text-gray-300',
            }
          : {
              icon: 'pending',
              background: 'bg-gray-300',
              text: 'text-gray-700',
              backgroundAndText: 'bg-gray-300 text-gray-700',
            };
      case 'DELETED':
      case 'WRITTEN_OFF':
        return inverse
          ? {
              icon: 'delete',
              background: 'bg-gray-700',
              text: 'text-gray-100',
              backgroundAndText: 'bg-gray-700 text-gray-100',
            }
          : {
              icon: 'delete',
              background: 'bg-gray-100',
              text: 'text-gray-700',
              backgroundAndText: 'bg-gray-100 text-gray-700',
            };
      case 'PROCESSED':
      case 'VALIDATED':
      case 'VERIFIED':
      case 'DISPATCHED':
        return inverse
          ? {
              icon: 'info',
              background: 'bg-blue-500',
              text: 'text-blue-100',
              backgroundAndText: 'bg-blue-500 text-blue-100',
            }
          : {
              icon: 'info',
              background: 'bg-blue-100',
              text: 'text-blue-500',
              backgroundAndText: 'bg-blue-100 text-blue-500',
            };

      case 'PENDING_REVIEW':
      case 'PENDING_INSTALLATION':
      case 'PENDING':
        return inverse
          ? {
              icon: 'pending',
              background: 'bg-blue-500',
              text: 'text-blue-100',
              backgroundAndText: 'bg-blue-500 text-blue-100',
            }
          : {
              icon: 'pending',
              background: 'bg-blue-100',
              text: 'text-blue-500',
              backgroundAndText: 'bg-blue-100 text-blue-500',
            };
      case 'INITIAL':
        return inverse
          ? {
              icon: 'info',
              background: 'bg-blue-700',
              text: 'text-blue-100',
              backgroundAndText: 'bg-blue-700 text-blue-100',
            }
          : {
              icon: 'info',
              background: 'bg-blue-100',
              text: 'text-blue-700',
              backgroundAndText: 'bg-blue-100 text-blue-700',
            };
      case 'OFFROAD':
      case 'PENDING_DEBT_COLLECTION':
      case 'ABANDONED':
        return inverse
          ? {
              icon: 'info',
              background: 'bg-orange-700',
              text: 'text-orange-100',
              backgroundAndText: 'bg-orange-700 text-orange-100',
            }
          : {
              icon: 'info',
              background: 'bg-orange-100',
              text: 'text-orange-700',
              backgroundAndText: 'bg-orange-100 text-orange-700',
            };
      case 'RESURRECTED':
      case 'PENDING_ACTIVATION':
        return inverse
          ? {
              icon: 'pending',
              background: 'bg-green-500',
              text: 'text-green-100',
              backgroundAndText: 'bg-green-500 text-green-100',
            }
          : {
              icon: 'pending',
              background: 'bg-green-100',
              text: 'text-green-500',
              backgroundAndText: 'bg-green-100 text-green-500',
            };
      case 'IN_REVIEW':
        return inverse
          ? {
              icon: 'info',
              background: 'bg-orange-700',
              text: 'text-orange-300',
              backgroundAndText: 'bg-orange-700 text-orange-300',
            }
          : {
              icon: 'info',
              background: 'bg-orange-300',
              text: 'text-orange-700',
              backgroundAndText: 'bg-orange-300 text-orange-700',
            };
      case 'PENDING_CLOSURE':
      case 'CLOSED':
        return inverse
          ? {
              icon: 'block',
              background: 'bg-black',
              text: 'text-white',
              backgroundAndText: 'bg-black text-white',
            }
          : {
              icon: 'block',
              background: 'bg-black',
              text: 'text-white',
              backgroundAndText: 'bg-black text-white',
            };
      default:
        return inverse
          ? {
              icon: 'info',
              background: 'bg-gray-700',
              text: 'text-gray-300',
              backgroundAndText: 'bg-gray-700 text-gray-300',
            }
          : {
              icon: 'info',
              background: 'bg-gray-300',
              text: 'text-gray-700',
              backgroundAndText: 'bg-gray-300 text-gray-700',
            };
    }
  }
  return null;
}
