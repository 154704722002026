export const BOOKING_STATUSES = [
  { name: 'Initial', value: 'INITIAL' },
  { name: 'Blocked', value: 'BLOCKED' },
  { name: 'Booked', value: 'BOOKED' },
  { name: 'Awaiting Allocation', value: 'AWAITING_ALLOCATION' },
  { name: 'Abandoned', value: 'ABANDONED' },
  { name: 'Allocated', value: 'ALLOCATED' },
  { name: 'En Route to Pickup', value: 'EN_ROUTE_TO_PICKUP' },
  { name: 'Approaching Pickup', value: 'APPROACHING_PICKUP' },
  { name: 'Arrived at Pickup', value: 'ARRIVED_AT_PICKUP' },
  { name: 'En Route to Destination', value: 'EN_ROUTE_TO_DESTINATION' },
  { name: 'Approaching Destination', value: 'APPROACHING_DESTINATION' },
  { name: 'Payment Due', value: 'PAYMENT_DUE' },
  { name: 'Completed', value: 'COMPLETED' },
  { name: 'Cancelled', value: 'CANCELLED' },
  { name: 'Expired', value: 'EXPIRED' },
];

export const BOOKING_STATUSES_STANDARD = [
  { name: 'Blocked', value: 'BLOCKED' },
  { name: 'Completed', value: 'COMPLETED' },
  { name: 'Cancelled', value: 'CANCELLED' },
  { name: 'Expired', value: 'EXPIRED' },
];

export const BOOKING_STATUSES_ADVANCED = [
  { name: 'Initial', value: 'INITIAL' },
  { name: 'Booked', value: 'BOOKED' },
  { name: 'Awaiting Allocation', value: 'AWAITING_ALLOCATION' },
  { name: 'Abandoned', value: 'ABANDONED' },
  { name: 'Allocated', value: 'ALLOCATED' },
  { name: 'En Route to Pickup', value: 'EN_ROUTE_TO_PICKUP' },
  { name: 'Approaching Pickup', value: 'APPROACHING_PICKUP' },
  { name: 'Arrived at Pickup', value: 'ARRIVED_AT_PICKUP' },
  { name: 'En Route to Destination', value: 'EN_ROUTE_TO_DESTINATION' },
  { name: 'Approaching Destination', value: 'APPROACHING_DESTINATION' },
  { name: 'Payment Due', value: 'PAYMENT_DUE' },
];

export const BOOKING_TAGS = [
  { name: 'Airport', value: 'AIRPORT' },
  { name: 'Review Required', value: 'REVIEW_REQUIRED' },
  { name: 'Review Completed', value: 'REVIEW_COMPLETED' },
  { name: 'Positive Rating', value: 'POSITIVE_RATING' },
  { name: 'Negative Rating', value: 'NEGATIVE_RATING' },
];

export const BOOKING_SEGMENTS = [
  { name: 'Business', value: 'BUSINESS' },
  { name: 'Consumer', value: 'CONSUMER' },
  { name: 'Hospitality', value: 'HOSPITALITY' },
  { name: 'Driver', value: 'DRIVER' },
];

export const BOOKING_CATEGORIES = [
  { name: 'Business', value: 'BUSINESS' },
  { name: 'Fraedom', value: 'FRAEDOM' },
  { name: 'Concur', value: 'CONCUR' },
  { name: 'Hotal', value: 'HOTEL' },
  { name: 'Restaurant', value: 'RESTAURANT' },
  { name: 'POS', value: 'POS' },
  { name: 'In App', value: 'IN_APP' },
  { name: 'Out of App', value: 'OUT_OF_APP' },
];

export const STAFF_SEARCH_STATUS = [
  { name: 'Invited', value: 'INVITED' },
  { name: 'Pending Approval', value: 'PENDING_APPROVAL' },
  { name: 'Active', value: 'ACTIVE' },
  { name: 'Suspended', value: 'SUSPENDED' },
];

export const GROUP_SEARCH_STATUS = [
  { name: 'ACTIVE', value: 'ACTIVE' },
  { name: 'INACTIVE', value: 'INACTIVE' },
];

export const PAYMENT_SEARCH_TYPES = [
  { name: 'Passenger card', value: 'PASSENGER_CARD' },
  { name: 'Organization card', value: 'ORGANISATION_CARD' },
  { name: 'Single use card', value: 'SINGLE_USE_CARD' },
  { name: 'Ingogo Busniess Mastercard', value: 'INGOGO_BUSINESS_MASTERCARD' },
  { name: 'Driver Card', value: 'DRIVER_CARD' },
  { name: 'Deferred Card', value: 'DEFERRED_CARD' },
];

export const PAYMENT_SEARCH_STATUS = [
  { name: 'Pending', value: 'PENDING' },
  { name: 'Active', value: 'ACTIVE' },
  { name: 'Suspended', value: 'SUSPENDED' },
  { name: 'Expired', value: 'EXPIRED' },
  { name: 'Closed', value: 'CLOSED' },
];

export const ADMINISTRATOR_SEARCH_STATUS = [
  { name: 'Deleted', value: true },
  { name: 'Active', value: false },
];
