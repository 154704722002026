import { HttpBackend, HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  ViewEncapsulation,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  BraintreeApiService,
  DriverApiService,
  IpApiServiceService,
} from '@fleet/api';
import {
  AddressModel,
  ApiResponse,
  ClientTokenModel,
  DriverModel,
  IssueModel,
  OperatorModel,
  TravellerModel,
  VerificationType,
} from '@fleet/model';
import * as braintree from 'braintree-web';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { transformBraintreeErrorToIssueModel } from '@fleet/utilities';
@Component({
  selector: 'fleet-braintree-hosted-fields',
  template: `
    <!-- 'animate-pulse': loading.value -->

    <form
      [ngClass]="{
        hidden: !hostedFieldsInstance,
      }"
      class="flex flex-col space-y-5 "
    >
      <div>
        <label class="font-medium " for="cardholder-name"
          >Card holder name</label
        >
        <div
          class="input-wrapper"
          id="cardholder-name"
          [ngClass]="{
            'border-primary-500 caret-primary-500': hostedFieldsEvent?.fields.cardholderName.isFocused,
            'border-red-500 caret-red-500':hostedFieldsEvent &&  !hostedFieldsEvent?.fields.cardholderName.isValid &&  !hostedFieldsEvent?.fields.cardholderName.isEmpty,
          }"
        ></div>
        <span
          *ngIf="
            hostedFieldsEvent &&
            !hostedFieldsEvent?.fields.cardholderName.isValid &&
            !hostedFieldsEvent?.fields.cardholderName.isEmpty
          "
          class="error-message"
        >
          The card holder name is not valid
        </span>
      </div>

      <div class="relative ">
        <label class="font-medium " for="card-number">Card Number</label>

        <div
          class="input-wrapper mt-1 mat-mdc-form-field-type-mat-input mat-form-field-appearance-fill md-text-field "
          [ngClass]="{
          'border-primary-500 caret-primary-500': hostedFieldsEvent?.fields.number.isFocused,
          'border-red-500 caret-red-500':hostedFieldsEvent && !hostedFieldsEvent?.fields.number.isValid && !hostedFieldsEvent?.fields.number.isEmpty,
        }"
          id="card-number"
        ></div>

        <span
          *ngIf="
            hostedFieldsEvent &&
            !hostedFieldsEvent?.fields.number.isValid &&
            !hostedFieldsEvent?.fields.number.isEmpty
          "
          class="error-message"
        >
          This card number is not valid.
        </span>

        <fleet-payment-method-icon
          *ngIf="cardType"
          [cardType]="cardType"
          [styleClass]="'h-6 w-14'"
          class="absolute top-10 end-1"
        >
        </fleet-payment-method-icon>
      </div>

      <div class="cardinfo-exp-date">
        <label class="font-medium " for="expiration-date">Expiry</label>
        <div
          class="input-wrapper"
          id="expiration-date"
          [ngClass]="{
            'border-primary-500 caret-primary-500': hostedFieldsEvent?.fields.expirationDate.isFocused,
            'border-red-500 caret-red-500':hostedFieldsEvent &&  !hostedFieldsEvent?.fields.expirationDate.isValid &&  !hostedFieldsEvent?.fields.expirationDate.isEmpty,
          }"
        ></div>
        <span
          *ngIf="
            hostedFieldsEvent &&
            !hostedFieldsEvent?.fields.expirationDate.isValid &&
            !hostedFieldsEvent?.fields.expirationDate.isEmpty
          "
          class="error-message"
        >
          This expiration date is not valid.
        </span>
      </div>

      <div class="cardinfo-cvv">
        <label class="font-medium " for="cvv">CVV</label>
        <div
          class="input-wrapper"
          id="cvv"
          [ngClass]="{
            'border-primary-500 caret-primary-500':hostedFieldsEvent?.fields.cvv.isFocused,
            'border-red-500 caret-red-500': hostedFieldsEvent && !hostedFieldsEvent?.fields.cvv.isValid && !hostedFieldsEvent?.fields.cvv.isEmpty,
          }"
        ></div>
        <span
          *ngIf="
            hostedFieldsEvent &&
            !hostedFieldsEvent?.fields.cvv.isValid &&
            !hostedFieldsEvent?.fields.cvv.isEmpty
          "
          class="error-message"
        >
          This security code is not valid.
        </span>
      </div>
    </form>
  `,
  styles: [
    `
      .input-wrapper {
        @apply border shadow-sm  bg-white rounded-[7px] min-h-[48px] px-4;
        @apply focus:border-primary-500;

        border-radius: 6px;

        display: block;
        font-size: 14px;
        height: 56px;
        outline: 0;
        width: 100%;

        line-height: 24px;

        box-shadow: none;

        position: relative;
      }

      .error-message {
        color: red;
        font-size: 12px;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class BraintreeHostedFieldsComponent implements OnInit, OnDestroy {
  currentIp: string;
  clientToken: ClientTokenModel;
  cardType: string;
  clientInstance: braintree.Client;
  latestHostedFieldsEvent: BehaviorSubject<braintree.HostedFieldsEvent> =
    new BehaviorSubject(null);
  hostedFieldsInstance: braintree.HostedFields;
  threeDSecureInstance: braintree.ThreeDSecure;
  threeDSecureParams: any;
  loadingMessage: string;
  loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  userCancelled = false;
  issues: BehaviorSubject<IssueModel[]> = new BehaviorSubject([]);

  @Output() nonceGenerated = new EventEmitter();
  @Output() braintreeError = new EventEmitter();

  _driver: DriverModel;
  @Input() set driver(value: DriverModel) {
    this._driver = value;
    if (value) {
      //  this.initialiseBrainTree();
    }
  }

  get driver() {
    return this._driver;
  }

  _traveller: any;
  @Input() set traveller(value: TravellerModel) {
    this._traveller = value;
    if (value) {
      //  this.initialiseBrainTree();
    }
  }
  get traveller() {
    return this._traveller;
  }

  _operator: OperatorModel;
  @Input() set operator(value: OperatorModel) {
    this._operator = value;
    if (value) {
      //  this.initialiseBrainTree();
    }
  }
  get operator() {
    return this._operator;
  }

  http: HttpClient;

  constructor(
    @Inject('env') env: any,
    private changeDetectorRef: ChangeDetectorRef,
    private braintreeApiService: BraintreeApiService,
    private ipApiService: IpApiServiceService,
    private backend: HttpBackend
  ) {
    this.http = new HttpClient(backend);
  }

  get hostedFieldsEvent(): braintree.HostedFieldsEvent {
    return this.latestHostedFieldsEvent.value;
  }
  get status$(): Observable<any> {
    return combineLatest([
      this.latestHostedFieldsEvent.asObservable(),
      this.loading.asObservable(),
      this.issues.asObservable(),
    ]).pipe(
      map(([hostedFields, loading, issues]) => {
        return {
          loading: loading,
          isValid: hostedFields
            ? Object.values(hostedFields.fields).every((field) => field.isValid)
            : false,
          isInitialised: hostedFields ? true : false,
          issues: issues,
        };
      })
    );
  }

  get issuesValue() {
    return this.issues.value;
  }

  ngOnInit() {
    this.ipApiService.getIp().subscribe((resp: any) => {
      this.currentIp = resp.ip;
      this.changeDetectorRef.markForCheck();
    });

    this.initialiseBrainTree();
  }

  initialiseBrainTree() {
    this.loading.next(true);
    this.loadingMessage = 'Initialising Secure Form';
    this.changeDetectorRef.markForCheck();
    this.braintreeApiService.getClientToken().subscribe({
      // this.getSampleClientToken().subscribe({
      next: (resp: ApiResponse<ClientTokenModel>) => {
        // this.clientToken = resp.client_token;
        this.clientToken = resp.data;
        braintree.client.create(
          {
            // authorization: resp.data.token,
            authorization: this.clientToken.token,
          },
          (err, clientInstance) => {
            if (err) {
              this.loading.next(false);
              this.issues.next(transformBraintreeErrorToIssueModel(err));
              this.braintreeError.emit(this.issues.value);
              this.changeDetectorRef.markForCheck();
              console.error(err);
              return;
            }
            this.clientInstance = clientInstance;
            if (this.clientToken.verificationType === 'CREDIT_CARD_3DS') {
              braintree.threeDSecure.create(
                {
                  version: 2,
                  client: this.clientInstance,
                },
                (err, threeDSecureInstance) => {
                  if (err) {
                    this.loading.next(false);

                    console.error(err);
                    return;
                  }
                  this.threeDSecureInstance = threeDSecureInstance;

                  threeDSecureInstance.on('customer-canceled', () => {
                    this.loading.next(false);

                    this.userCancelled = true;
                    console.log('customer cancelled');
                    //this.loading = false;
                    this.changeDetectorRef.markForCheck();
                  });
                }
              );
            }
            this.initHostedFields();
          }
        );
      },
      error: (issues: IssueModel[]) => {
        this.loading.next(false);
        this.issues.next(issues);
        this.braintreeError.emit(this.issues.value);
        this.changeDetectorRef.markForCheck();
      },
    });
  }

  initHostedFields() {
    const root = this;

    braintree.hostedFields.create(
      {
        client: this.clientInstance,
        styles: {
          input: {
            'font-size': '14px',
            'caret-color': 'inherit',
            transition: '0.1s ease-out',
          },
          ':focus': {
            color: 'black',
          },
        },
        fields: {
          cardholderName: {
            selector: '#cardholder-name',
            placeholder: 'John Doe',
          },
          number: {
            selector: '#card-number',
            placeholder: '4111 1111 1111 1111',
          },
          cvv: {
            selector: '#cvv',
            placeholder: '123',
          },
          expirationDate: {
            selector: '#expiration-date',
            placeholder: 'MM/YYYY',
          },
        },
      },
      (err, hostedFieldsInstance) => {
        if (err) {
          root.issues.next(transformBraintreeErrorToIssueModel(err));
          root.loading.next(false);
          root.changeDetectorRef.markForCheck();
          root.braintreeError.emit(root.issues.value);
          console.error(err);
          return;
        }
        root.hostedFieldsInstance = hostedFieldsInstance;
        root.loading.next(false);
        root.loadingMessage = null;
        root.changeDetectorRef.markForCheck();

        hostedFieldsInstance.focus('cardholderName');

        hostedFieldsInstance.on(
          'focus',
          (event: braintree.HostedFieldsEvent) => {
            console.log('Field focus');
            root.latestHostedFieldsEvent.next(event);
            root.changeDetectorRef.detectChanges();
          }
        );

        hostedFieldsInstance.on('cardTypeChange', (event) => {
          root.latestHostedFieldsEvent.next(event);
          root.cardType = event.cards[0] ? event.cards[0].type : null;
          console.log(root.cardType);

          root.changeDetectorRef.detectChanges();
        });

        hostedFieldsInstance.on('validityChange', (event) => {
          console.log('validatity chnage');
          root.latestHostedFieldsEvent.next(event);
          root.changeDetectorRef.detectChanges();
        });
      }
    );
    //   }
    // );
  }

  requestPayment(billingInformation: any) {
    this.loading.next(true);
    this.loadingMessage = 'Verifying card';
    this.changeDetectorRef.markForCheck();
    const root = this;
    this.hostedFieldsInstance.tokenize((err, payload) => {
      if (err) {
        root.issues.next(transformBraintreeErrorToIssueModel(err));
        root.loading.next(false);
        root.braintreeError.emit(this.issues.value);
        return;
      }
      console.log('Tokenize resp:');
      console.log(payload);
      if (
        this.clientToken.verificationType ===
          VerificationType.CREDIT_CARD_3DS &&
        this.threeDSecureInstance
      ) {
        const secureOptions = {} as any;
        secureOptions.amount = '0.00';
        secureOptions.bin = payload.details.bin;
        secureOptions.nonce = payload.nonce;

        //Lets get our info from the billing address form;

        secureOptions.email = billingInformation?.email
          ? billingInformation.email
          : null;

        secureOptions.collectDeviceData = true;
        secureOptions.challengeRequested = true;

        const combinedThreeDSecurePayload = {
          ...secureOptions,
          billingAddress: {
            givenName: billingInformation?.givenName,
            surname: billingInformation?.surname,
            phoneNumber: billingInformation?.phoneNumber,
          },
          additionalInformation: {
            ipAddress: this.currentIp,
          },
        };

        console.log(
          'threeDSecure-verifyCard() - Payload:',
          combinedThreeDSecurePayload
        );
        this.threeDSecureInstance.verifyCard(
          {
            ...secureOptions,
            billingAddress: {
              givenName: billingInformation.givenName,
              surname: billingInformation.surname,
              phoneNumber: billingInformation.phoneNumber,
            },
            additionalInformation: {
              ipAddress: this.currentIp,
            },
            // {
            //   ...secureOptions,

            onLookupComplete: (data: any, next: any) => {
              console.log('Lookup complete:' + data);

              // use 'data' here, then call 'next()'
              next();
            },
          },
          (error, threeDPayload) => {
            if (error) {
              root.issues.next(transformBraintreeErrorToIssueModel(error));
              root.loading.next(false);
              root.braintreeError.emit(root.issues.value);

              root.changeDetectorRef.markForCheck();
              console.error(error);
              return;
            }
            if (!this.userCancelled) {
              root.loadingMessage = 'Saving card';

              const payment = {
                nonce: threeDPayload.nonce,
                type: 'BRAINTREE',
              };

              root.nonceGenerated.emit(payment);
              //root.loading.next(false);
              root.changeDetectorRef.markForCheck();
            } else {
              //reset
              root.loading.next(false);
              root.loadingMessage = null;
              this.userCancelled = false;
              root.changeDetectorRef.markForCheck();
            }
            // Send payload.nonce to your server
          }
        );
      } else {
        const payment = {
          nonce: payload.nonce,
          type: 'BRAINTREE',
        };
        this.nonceGenerated.emit(payment);
      }
      // const payment = {
      //   nonce: payload.nonce,
      //   type: 'BRAINTREE',
      // };
      // this.nonceGenerated.emit(payment);
      // this.loading = false;
      // this.changeDetectorRef.markForCheck();
      root.changeDetectorRef.markForCheck();

      // Send payload.nonce to your server
    });
  }

  threeDSecureAddressParamsFromAddressModel(address: AddressModel) {
    return {
      streetAddress: address.streetNumber + ' ' + address.streetName,
      locality: address.locality,
      region: address.regionCode,
      postalCode: address.postalCode,
      countryCodeAlpha3: address.countryCode,
    };
  }

  ngOnDestroy() {
    // Clean up any resources that can create memory leaks
    if (this.threeDSecureInstance) {
      this.threeDSecureInstance.teardown();
    }
    if (this.hostedFieldsInstance) {
      this.hostedFieldsInstance.teardown();
    }
  }

  getSampleClientToken(): Observable<any> {
    return this.http.get(
      'https://braintree-sample-merchant.herokuapp.com/client_token'
    );
  }
}
