import { FLEET_VERSION } from '@fleet/version';
export const environment = {
  production: true,
  hmr: false,
  xApiKey: 'pk_test_979473aac2e1c7b4dc7f85430ba0eade',
  host: 'https://preprod-tapi-test1.us1.fleet-dev.com',
  googleApiKey: 'AIzaSyDOOk5iG_N8crmcgz_fvooLtY3C9hSIVwA',
  braintreeWebClientToken: 'sandbox_bnnjgsp2_37d8t9fxb3ydvmxs',
  minTokenExpiry: 60,
  fleetProduct: 'TRAVELLER',
  envName: 'test1-us',
  socketHost: 'wss://preprod-svc-socket.us1.fleet-dev.com/socket',
  socketTopicPrefix: '/topic/test1/core/v3/',

  socketAcknowledge:
    'https://preprod-svc-socket.us1.fleet-dev.com/socket/client/{{clientId}}/acknowledge/',
  tasHost: 'https://preprod-svc-tas.us1.fleet-dev.com',
  version: FLEET_VERSION,
};
